import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function OrchidprojectPage() {
  return (
    <article>
      <h1>Orchid Project</h1>
      <section>
        <p>
          Orchid Project are an NGO catalysing the global movement to end female genital cutting (FGC). FGC is
          a human rights violation that harms the lives of girls, women and their communities. Orchid Project
          partner with pioneering grassroots organisations around the world, and share knowledge and best
          practice to accelerate change. Orchid Project also advocate among governments and global leaders to
          ensure work to end FGC is prioritised.
        </p>
        <StaticImage src="../images/Orchid-Logo.png" alt="Orchid Project Logo" />
        <p>
          Find out more about them and their amazing work at:{" "}
          <a href="https://www.orchidproject.org/" title="Orchid Project" target="_blank" rel="noreferrer">
            orchidproject.org
          </a>
        </p>
      </section>
    </article>
  );
}
